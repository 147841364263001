import React from 'react';

function About() {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the about page of our interactive React app.</p>
    </div>
  );
}

export default About;
