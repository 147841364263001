import React, { useEffect, useRef, useState } from 'react';
import './home.css';
import logo from './images/logo.jpeg';
import gif2 from './images/godcat.mp4';
import gif3 from './images/gif1.mp4';
import { IconButton } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

function Home() {
  // State for count-up
  const [count, setCount] = useState(0);
  const targetNumber = 1000000000; // Set the target number to 1 billion
  const duration = 5000; // Total duration of the count-up in milliseconds
  const interval = 30; // Interval in milliseconds for updating the number

  // Function to handle Telegram button click
  const handleTelegramClick = () => {
    window.open('https://t.me/godcatCTO123', '_blank');
  };

  // Function to handle Twitter button click
  const handleTwitterClick = () => {
    window.open('https://x.com/Godcat_takeover', '_blank');
  };

  // Ref for the second div to observe
  const secondDivRef = useRef(null);

  useEffect(() => {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll');
        } else {
          entry.target.classList.remove('animate-on-scroll');
        }
      });
    }, { threshold: 0.1 });

    if (secondDivRef.current) {
      observer.observe(secondDivRef.current);
    }

    return () => {
      if (secondDivRef.current) {
        observer.unobserve(secondDivRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Count-up animation logic
    const increment = Math.ceil(targetNumber / (duration / interval));
    const countInterval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < targetNumber) {
          return Math.min(prevCount + increment, targetNumber);
        } else {
          clearInterval(countInterval);
          return targetNumber;
        }
      });
    }, interval);

    // Cleanup interval on component unmount
    return () => clearInterval(countInterval);
  }, [targetNumber, duration, interval]);

  // Prevent fullscreen on mobile
  const preventFullscreen = (event) => {
    if (event.target.requestFullscreen) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className='div-bg-one'>
        <img id='logo' src={logo} alt='logo' />
        <h1 className='welcome-msg'>Welcome to $GodCat</h1>
        <p>It’s time for GODCAT to save everyone on Base chain. The ruler of mankind and all cats. Godcat inspired by the Netflix series with over 277m subscribers. <br />Card game with over 35m copies sold.</p>
        <div className="icon-buttons">
          <IconButton color="primary" onClick={handleTelegramClick}>
            <TelegramIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleTwitterClick}>
            <TwitterIcon />
          </IconButton>
        </div>
      </div>

      <div className='div-bg-two' ref={secondDivRef}>
        <h1 className='div-two-msg'>TOKENOMICS</h1>
        <video
          id='div-two-video'
          src={gif2}
          autoPlay
          loop
          muted
          controls
          onWebkitBeginFullscreen={preventFullscreen}
          onWebkitEndFullscreen={preventFullscreen}
        />
        <h1>Total Supply</h1>
        <p>{count.toLocaleString()}</p>
        <div className="icon-buttons">
          <IconButton color="primary" onClick={handleTelegramClick}>
            <TelegramIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleTwitterClick}>
            <TwitterIcon />
          </IconButton>
        </div>
      </div>

      <div className='div-bg-two' ref={secondDivRef}>
        <h1 id='ca'>CA</h1>
        <p id='addy'>0xdb08ca267e824fb29eb455b7e06fe73fb0370895</p>
        <h1>Burnt: 7%</h1>
        <p>71,000,000</p>
        <h1>Tax</h1>
        <p id='tax'>0%</p>
      </div>

      <div className='div-bg-three'>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <video
              id='div-three-video'
              src={gif3}
              autoPlay
              loop
              muted
              controls
              onWebkitBeginFullscreen={preventFullscreen}
              onWebkitEndFullscreen={preventFullscreen}
            />
          </SwiperSlide>
          <SwiperSlide>
            <video
              id='div-three-video'
              src={gif3}
              autoPlay
              loop
              muted
              controls
              onWebkitBeginFullscreen={preventFullscreen}
              onWebkitEndFullscreen={preventFullscreen}
            />
          </SwiperSlide>
          <SwiperSlide>
            <video
              id='div-three-video'
              src={gif2}
              autoPlay
              loop
              muted
              controls
              onWebkitBeginFullscreen={preventFullscreen}
              onWebkitEndFullscreen={preventFullscreen}
            />
          </SwiperSlide>
          <SwiperSlide>
            <video
              id='div-three-video'
              src={gif2}
              autoPlay
              loop
              muted
              controls
              onWebkitBeginFullscreen={preventFullscreen}
              onWebkitEndFullscreen={preventFullscreen}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Home;