import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import Home from './components/Home';
import About from './components/About';
import ShowChartIcon from '@mui/icons-material/ShowChart'; // Import the icon

const dexlink = "https://dexscreener.com/base/0xa55c2eb0561dc1ece7c1ad0d62fae7dc1029b120";
const dextools = "https://www.dextools.io/app/en/token/godcat?t=1724637537348";

function App() {
  // Function to handle DEXTOOLS button click
  const handleDexLinkClick = () => {
    window.open(dextools, '_blank'); // Open DEXTOOLS URL in a new tab
  };

  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            $GODCAT
          </Typography>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" onClick={handleDexLinkClick} startIcon={<ShowChartIcon />}>
            DEXTOOLS
          </Button>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;